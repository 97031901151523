.button {
  pointer-events: all;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;
  color: transparent;
  outline: none;
  width: 65px;
  height: 65px !important;
  margin-top: 10px;
  transition: transform 0.5s cubic-bezier(0.3, -0.4, 0, 1.5);
}

[hidden] .button:not(.buttonOpened) {
  transform: translateY(7em);
}

.buttonLabel {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.chatButton {
  position: absolute;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s linear, filter 0.3s linear;
}

.chatButtonBackside {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.buttonOpened .chatButtonBackside {
  transform: rotateY(0deg);
  backface-visibility: visible;
}

.buttonOpened .chatButtonFrontside {
  transform: rotateY(180deg);
}

.button:not(.buttonOpened):hover {
  filter: brightness(2) drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.36));
  animation: wiggle 1s;
}

.buttonOpened:hover .chatButton {
  animation: none;
}

.counter {
  z-index: 1;
  display: none;
  position: absolute;
  width: 24px;
  height: 24px !important;
  top: -6px;
  left: auto;
  right: -6px;
  border: 2.4px solid #000;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
}

.withCounter .counter {
  display: flex;
}

@keyframes wiggle {
  0% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }
  20% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(18deg);
  }
  40% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(-18deg);
  }
  60% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(8deg);
  }
  80% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(-8deg);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }
}

@media (max-width: 640px) {
  .initialWiggle {
    animation: wiggle 1s;
    animation-delay: 0.3s;
  }
}
